@use "Styles" as *;

.traceId {
  text-align: center;
  font-size: 1rem;
}
.pageNotFoundContainer {
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 60vh;
  text-align: center;
  font-family: 'GT Eesti Display', serif;
  h1 {
    text-transform: uppercase;
    font-size: 3.45rem;
    font-weight: 700;
    margin-top: 1.25rem;
  }
  h2 {
    font-size: 1rem;
    margin: 2rem 0;
  }
  button {
    border-radius: 1rem;
    color: #ffffff;
    background: #000000;
    padding: 0.75rem;
  }
}
